/***
 * 计算字符串长度(英文占1个字符，中文汉字占2个字符)
 * @param str
 * @returns {number}
 */
 export function strlen(str){
  var len = 0;
  for (var i=0; i<str.length; i++) {
    var c = str.charCodeAt(i);
    //单字节加1
    if ((c >= 0x0001 && c <= 0x007e) || (0xff60<=c && c<=0xff9f)) {
      len++;
    }
    else {
      len+=2;
    }
  }
  return len;
}


/***
 * 截取字符串前n位
 * @param str
 * @param n
 * @returns {string}
 */
 export function substring(str,n){
    let len = 0;
    let res = '';
    for (let i=0; i<str.length; i++) {
      let c = str.charCodeAt(i);
      //单字节加1
      if ((c >= 0x0001 && c <= 0x007e) || (0xff60<=c && c<=0xff9f)) {
        len++;
      }
      else {
        len+=2;
      }
      if(len <= n){
        res += str[i];
      }
    }
    return res;
  }