<template>
  <!-- 讨论组分类Popover -->
  <el-dialog
    title=""
    :visible.sync="
      $store.state.discussionGroupClassification
        .moveDiscussionGroupClassificationPopoverVisible
    "
    custom-class="moveDiscussionGroupClassificationPopover"
    :close-on-click-modal="false"
    center
    :destroy-on-close="true"
  >
    <div class="discussion-header">
      <div style="height: 100%; display: flex; align-items: center">
        <i
          v-if="groupRouters.length > 1"
          class="el-icon-arrow-left"
          style="cursor: pointer; font-size: 16px;margin-top:1px"
          @click="goBack"
        ></i>

        <div v-if="groupRouters.length > 1" class="line"></div>

        <span
          class="iconfont_Me icon-a-gundongzu47"
          style="font-size: 16px; color: #333333; margin-right: 12px"
        ></span>
        <!-- 路径 -->
        <div
          class="groupRouters"
          :style="{ width: groupRouters.length > 1 ? '63%' : '69%' }"
        >
          <template v-if="groupRouters.length <= 4">
            <span
              v-for="(item, inx) in groupRouters"
              :key="inx"
              @click="goPage(item, inx)"
              :class="inx == groupRouters.length - 1 ? 'active' : ''"
            >
              <span v-if="inx != 0">/</span>
              <i v-if="strLength(item.text) <= maxNameLength">{{
                item.text
              }}</i>
              <i v-if="strLength(item.text) > maxNameLength">{{
                inx == 0
                  ? item.text
                  : substring(item.text, maxNameLength) + "..."
              }}</i>
            </span>
          </template>
          <template v-if="groupRouters.length > 4">
            <span @click="goHome()">{{ $t("myCategory") }}>...></span>
            <span
              v-for="(item, inx) in groupRouters.slice(-4)"
              :key="inx"
              @click="goPage(item, inx + (groupRouters.length - 4))"
              :class="inx == groupRouters.length - 1 ? 'active' : ''"
            >
              <span v-if="inx != 0">/</span>
              <i v-if="strLength(item.text) <= maxNameLength">{{
                item.text
              }}</i>
              <i v-if="strLength(item.text) > maxNameLength"
                >{{ substring(item.text, maxNameLength) }}...</i
              >
            </span>
          </template>
        </div>
        <!-- 搜索 -->
        <div class="discussion-input">
          <el-input
            class="class-input"
            size="mini"
            ref="inputs"
            v-model="searchClassValue"
            @keyup.enter.native="handlerSearchClass"
          >
            <template #suffix>
              <i
                class="el-icon-search imelink-searchbar__submit"
                @click="handlerSearchClass"
              ></i>
            </template>
          </el-input>
        </div>
        <!-- 关闭 -->
        <div class="discussion-close" @click="close">
          <i class="el-icon-close"></i>
        </div>
      </div>
    </div>
    <div class="discussion-title">
      <div
        class="currentInfo"
        v-if="showMoveInfo.name"
        :title="showMoveInfo.name"
      >
        <span class="iconfont_Me icon-a-gundongzu47 iconStyle"></span>
        <div class="imageStyle">
          <el-image
            style="width: 100%;"
            :style="{
              'border-radius': showMoveInfo.nodeType == 1 ? '50%' : '',
            }"
            :src="showMoveInfo.icon"
            :fit="'cover'"
          ></el-image>
        </div>
        <div class="textStyle">
          {{ showMoveInfo.name }}
        </div>
      </div>
      <div class="discussion-add">
        <img
          @click="addClass"
          src="../../../assets/images/class/add-class.png"
        />
      </div>
    </div>
    <el-progress
      v-if="percentage < 100"
      :percentage="percentage"
      :show-text="false"
      stroke-linecap="dashboard"
    >
    </el-progress>
    <!-- 用个div占位，页面抖动 -->
    <div v-else style="width: 100%; height: 1px"></div>
    <div class="class-cont">
      <template v-if="showSearchWrap">
        <div
          class="cont-wrap search-warp"
          v-scroll="{
            distance: 100,
            onBottom: _onSearchClassListBottom,
          }"
        >
          <Folders
            :folders="searchFoldersList"
            :isSearch="showSearchWrap"
            :operatingMenu="[]"
            :loading="loading"
            :foldersType="showFoldersType"
            :modifyMode="false"
            :isMoving="true"
            @selectThis="isSearchClick"
            @loadData="_onSearchClassListBottom"
          ></Folders>
          <template v-if="searchFoldersList.length == 0" v-else>
            <div class="no-data">
              <img src="../../../assets/images/class/no-data-img.png" />
            </div>
          </template>
        </div>
      </template>
      <template v-else>
        <div class="cont-wrap">
          <Folders
            :folders="foldersList"
            :operatingMenu="[]"
            :loading="loading"
            :foldersType="showFoldersType"
            :modifyMode="false"
            :isMoving="true"
            @selectThis="isClick"
            @loadData="_onClassListBottom"
          ></Folders>
          <div v-if="foldersList.length == 0" class="no-data">
            <img src="../../../assets/images/class/no-data-img.png" />
          </div>
        </div>
      </template>
    </div>

    <!-- 操作 -->
    <div class="discussion-operat">
      <el-button class="close" icon="el-icon-close" @click="close"></el-button>
      <el-button
        class="check"
        :disabled="disable"
        icon="el-icon-check"
        @click="check"
      ></el-button>
    </div>
  </el-dialog>
</template>
<script>
import {
  addChatClass,
  chatClassList,
  deleteClass,
  moveClass,
} from "@/api/newVersion/discussionGroupClassification";
import { substring, strlen } from "@/utils/number";
import Folders from "@/components/common/imelink/components/myClassification/components/folders.vue";
export default {
  components: {
    Folders,
  },
  computed: {
    // 当前收藏夹节点
    currentCollectListRouter() {
      if (this.groupRouters.length == 0) {
        this.groupRouters = [
          {
            name: "home",
            text: this.$t("myCategory"),
          },
        ];
      }
      return this.groupRouters[this.groupRouters.length - 1];
    },
    showFoldersType() {
      return this.$store.state.discussionGroupClassification
        .moveDiscussionGroupClassificationPopoverMode;
    },
  },
  filters: {
    formatDate: function(value) {
      let date = new Date(Number(value));
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return y + "-" + MM + "-" + d;
    },
  },
  data() {
    return {
      //群分组自定义路由
      groupRouters: [
        {
          name: "home",
          text: this.$t("myCategory"),
        },
      ],
      chatClassListPage: 1,
      chatClassListPageSize: 80,
      searchValue: "",
      percentage: 0,
      isCheckedFloderIndex: -1,
      loading: false,
      foldersList: [],
      operatingMenu: [
        {
          iconClassName: "icon-write",
          tooltipContent: this.$t("editpost"),
        },
        {
          iconClassName: "icon-Iconly-Light-Delete-copy",
          tooltipContent: this.$t("delete"),
        },
      ],
      visible: false,
      disable: false,
      showSearchWrap: false,
      searchFoldersList: [],
      searchClassValue: "",
      maxNameLength: 10,
      showMoveInfo: {
        nodeType: 0,
        icon: null,
        name: "",
      },
    };
  },
  props: {},
  watch: {
    // 监听是否需要刷新收藏夹列表
    "$store.state.favorites.needRefreshImFavoritesList": {
      async handler(val, old) {
        if (val && val !== old) {
          this.chatClassListPage = 1;
          await this.getChatClassList();
          this.$store.commit("setNeedRefreshImFavoritesList", false);
        }
      },
      deep: true,
    },
  },
  async mounted() {
    // 监听鼠标左键点击
    document.addEventListener("click", (e) => {
      this.visible = false;
    });
    this.searchClassValue = "";
    this.showSearchWrap = false;
    this.chatClassListPage = 1;
    await this.getChatClassList();
    let discussionGroupClassificationInfo = _.cloneDeep(
      this.$store.state.discussionGroupClassification
        .discussionGroupClassificationInfo
    );
    if (discussionGroupClassificationInfo.nodeType == 0) {
      this.showMoveInfo.nodeType = 0;
      this.showMoveInfo.name = discussionGroupClassificationInfo.nodeData;
      this.showMoveInfo.icon = require("../../../assets/images/class/folder-icon.png");
    } else if (discussionGroupClassificationInfo.nodeType == 1) {
      this.showMoveInfo.nodeType = 1;
      this.showMoveInfo.name =
        discussionGroupClassificationInfo.shortInfoVo.chatName;
      this.showMoveInfo.icon =
        discussionGroupClassificationInfo.shortInfoVo.profilePhoto;
    }
  },
  beforeDestroy() {
    document.removeEventListener("click", (e) => {
      this.visible = false;
    });
  },
  methods: {
    async handlerChatTo(item) {
      let params = {
        id: item.shortInfoVo.chatId,
        displayName: item.shortInfoVo.chatName.replace(/<\/?.+?\/?>/g, ""),
        avatar: item.shortInfoVo.profilePhoto,
        isGroup: true,
      };
      await this.$openIM(params, this.$store.state.imStore.IMWindowSize);
      this.close();
    },
    rowContextmenu(inx) {
      if (this.isCheckedFloderIndex == inx) {
        this.visible = !this.visible;
      } else {
        this.visible = true;
      }
      this.isCheckedFloderIndex = inx;
    },
    //添加分类
    addClass() {
      let parentId = null;
      if (this.currentCollectListRouter.id) {
        parentId = this.currentCollectListRouter.id;
      }
      this.$prompt(`${this.$t("newfolder")}（≤40）`, "", {
        confirmButtonText: " ",
        cancelButtonText: " ",
        center: true,
        showClose: true,
        cancelButtonClass: "el-icon-close my-confirm-btn",
        confirmButtonClass: "el-icon-check my-confirm-btn",
        inputPattern: /^.{1,40}$/,
        inputErrorMessage: this.$t("extralongtips"),
      })
        .then(async ({ value }) => {
          let params = {
            nodeData: value,
            nodeType: 0,
          };
          if (parentId) {
            params.parentId = parentId;
          }
          let result = await addChatClass(params);
          if (result.message == "success") {
            this.$message({
              type: "success",
              message: this.$t("addedSuccessfully"),
            });
            this.chatClassListPage = 1;
            await this.getChatClassList();
          } else {
            this.$message({
              type: "error",
              message: result.message,
            });
          }
        })
        .catch(() => {});
    },
    close() {
      this.searchClassValue = "";
      this.showSearchWrap = false;
      this.groupRouters = [
        {
          name: "home",
          text: this.$t("myCategory"),
        },
      ];
      this.$store.commit(
        "setMoveDiscussionGroupClassificationPopoverVisible",
        false
      );
    },
    selectThis() {},
    // 收藏列表拉取更多
    async _onClassListBottom() {
      this.chatClassListPage++;
      await this.getChatClassList();
    },
    // 获取收藏列表、
    async getChatClassList(data) {
      if (!data) {
        data = this.currentCollectListRouter;
      }
      let discussionGroupClassificationInfo = _.cloneDeep(
        this.$store.state.discussionGroupClassification
          .discussionGroupClassificationInfo
      );
      this.percentage = 0;
      this.interVal = setInterval(() => {
        this.percentage++;
        if (this.percentage > 90) {
          clearInterval(this.interVal);
        }
      }, 100);
      this.visible = false;

      const pageInfo = {
        page: this.chatClassListPage,
        pageSize: this.chatClassListPageSize,
      };

      data = {
        ...data,
        ...pageInfo,
      };

      if (!data.parentId && data.id) {
        data.parentId = data.id;
      }

      let result;
      this.loading = true;
      if (data && data.id) {
        result = await chatClassList(data);
      } else {
        this.groupRouters = [
          {
            name: "home",
            text: this.$t("myCategory"),
          },
        ];
        result = await chatClassList(data);
      }
      if (result.code == 200) {
        this.percentage = 100;
        clearInterval(this.interVal);
        if (result.data.data.length != 0 || this.chatClassListPage == 1) {
          let foldersList = [];
          if (this.chatClassListPage == 1) {
            foldersList = result.data.data;
          } else {
            foldersList = this.foldersList.concat(result.data.data);
          }
          this.foldersList = foldersList.map((folder, index) => {
            folder.refreshKey = Date.now() + index;
            return folder;
          });
          this.foldersList.map((item) => {
            if (item.id == discussionGroupClassificationInfo.id) {
              item.isDisbale = true;
            }
          });
          // 如果当前是需要移动的文件所在文件夹，则禁止移动
          this.disable = this.foldersList.some((item) => {
            return item.id == discussionGroupClassificationInfo.id;
          });
        } else {
          this.chatClassListPage = result.data.pages;
        }
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
      this.loading = false;
    },
    // 选择操作菜单的选项
    selectThisMenuItem(index, operatingItem) {
      switch (index) {
        case 0:
          // 这一步是展示添加新收藏夹组件
          this.$store.commit("setNeedToShowInDialog", "editImFolderBlock");
          this.$store.commit("setNeedEditImFavoritesInfo", operatingItem);
          // 打开收藏操作弹窗
          this.$store.commit("setFavoritesDialogStatus", true);
          this.visible = false;
          break;
        case 1:
          this.$confirm("", "", {
            confirmButtonText: " ",
            cancelButtonText: " ",
            type: "warning",
            center: true,
            iconClass: "iconfont_Me icon-delet-fill",
            customClass: "my-confirm",
            cancelButtonClass: "el-icon-close my-confirm-btn",
            confirmButtonClass: "el-icon-check my-confirm-btn",
          })
            .then(async () => {
              await this.deleteSelectedFavorites(operatingItem);
            })
            .catch((e) => {
              this.visible = false;
            });
          break;
      }
    },
    // 删除选中的收藏夹
    async deleteSelectedFavorites(operatingItem) {
      let result = await deleteClass(operatingItem);
      if (result.code == 200) {
        this.$message({
          type: "success",
          message: this.$t("successfullyDeleted"),
        });
        this.chatClassListPage = 1;
        await this.getChatClassList();
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    async isSearchClick(data) {
      if (data.isDisbale) {
        return false;
      }
      this.showSearchWrap = false;
      await this.isClick(data);
    },
    async isClick(data) {
      if (data.isDisbale) {
        return false;
      }
      let discussionGroupClassificationInfo = _.cloneDeep(
        this.$store.state.discussionGroupClassification
          .discussionGroupClassificationInfo
      );
      // 点击的是被移动文件自己则禁止点击
      if (discussionGroupClassificationInfo.id == data.id) {
        return false;
      }
      if (data.nodeType == 1) {
        this.handlerChatTo(data);
        return;
      }
      this.fillingRouters(this.groupRouters, data);
      this.chatClassListPage = 1;
      await this.getChatClassList();
    },
    // 装填路由
    fillingRouters(routers, content) {
      let isRepeat = routers.find((value) => {
        return value.id == content.id;
      });
      if (!isRepeat) {
        routers.push({
          name: content.nodeData,
          id: content.id,
          text: content.nodeData,
        });
      }
    },
    async goPage(item, inx) {
      if (this.groupRouters.length > inx + 1) {
        this.groupRouters = this.groupRouters.splice(0, inx + 1);
      }
      this.showSearchWrap = false;
      this.searchClassValue = "";
      this.chatClassListPage = 1;
      await this.getChatClassList(item);
    },
    //确认移动
    async check() {
      let discussionGroupClassificationInfo = _.cloneDeep(
        this.$store.state.discussionGroupClassification
          .discussionGroupClassificationInfo
      );
      if (discussionGroupClassificationInfo) {
        let params = {
          id: discussionGroupClassificationInfo.id,
        };

        if (this.currentCollectListRouter.id) {
          params.targetFolderId = this.currentCollectListRouter.id;
        }
        let result = await moveClass(params);
        if (result.message == "success") {
          this.$message({
            type: "success",
            message: this.$t("moveSuccessfully"),
          });
          if (
            this.$store.state.discussionGroupClassification.searchClassValue
          ) {
            this.$store.commit(
              "setDiscussionSearchRefreshImFavoritesList",
              true
            );
          }
          // 通知收藏模块已移动成功，需要刷新
          this.$store.commit("setMoveState", true);
          // 告诉收藏列表需要跳转位置的对象信息
          this.$store.commit(
            "setClassifiedInfoOfNeedMove",
            discussionGroupClassificationInfo
          );
          this.$store.commit("setNeedRefreshSeamlessRefresh", true);
          this.chatClassListPage = 1;
          await this.getChatClassList();
          this.close();
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
        }
      }
    },
    // 获取搜索数据
    async getsearchClassList(data) {
      this.percentage = 0;
      this.interVal = setInterval(() => {
        this.percentage++;
        if (this.percentage > 90) {
          clearInterval(this.interVal);
        }
      }, 100);
      this.visible = false;
      let result;
      result = await chatClassList(data);
      if (result.code == 200) {
        this.percentage = 100;
        clearInterval(this.interVal);
        if (result.data.data.length != 0 || this.chatClassListPage == 1) {
          let searchFoldersList = [];
          if (this.chatClassListPage == 1) {
            searchFoldersList = result.data.data;
          } else {
            searchFoldersList = this.searchFoldersList.concat(result.data.data);
          }
          this.searchFoldersList = searchFoldersList.map((folder, index) => {
            folder.refreshKey = Date.now() + index;
            return folder;
          });
          let discussionGroupClassificationInfo = _.cloneDeep(
            this.$store.state.discussionGroupClassification
              .discussionGroupClassificationInfo
          );
          let parentId = discussionGroupClassificationInfo.id;

          this.searchFoldersList.map((item) => {
            if (item.id == parentId) {
              item.isDisbale = true;
            }
          });

          this.searchFoldersList.forEach((item) => {
            if (item.parentsPath.indexOf(parentId) != -1) {
              item.isDisbale = true;
            }
            if (item.id == parentId) {
              item.isDisbale = true;
            }
          });
        } else {
          this.chatClassListPage = result.data.pages;
        }
      }
    },
    // 获取更多搜索数据
    async _onSearchClassListBottom() {
      this.chatClassListPage++;
      await this.handlerSearchClass(false);
    },
    handlerSearchClass(firstRefresh = true) {
      if (!this.searchClassValue) {
        return;
      }
      let parentId = null;
      if (this.groupRouters.length > 1) {
        parentId = this.currentCollectListRouter.id;
      }
      if (firstRefresh) {
        this.chatClassListPage = 1;
      } else {
        this.chatClassListPage++;
      }
      let params = {
        searchClassValue: this.searchClassValue,
        page: this.chatClassListPage,
        pageSize: this.chatClassListPageSize,
      };
      if (parentId) {
        params.parentId = parentId;
      }
      this.getsearchClassList(params);
      this.showSearchWrap = true;
    },
    strLength(str) {
      return strlen(str);
    },
    substring(str, n) {
      return substring(str, n);
    },
    goHome() {
      this.goPage({ name: "home", text: this.$t("myCategory") }, 0);
    },
    goBack() {
      let len = this.groupRouters.length;
      if (len < 2) {
        return false;
      }
      this.goPage(this.groupRouters[len - 2], len - 2);
    },
  },
};
</script>

<style lang="scss">
.el-dialog.moveDiscussionGroupClassificationPopover {
  // max-width: 766px !important;
  width: 766px;
  user-select: none;

  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    padding: 0;
  }

  .discussion-header {
    height: 49px;
    padding: 0px 20px 0 26px;
    background: #f9f9f9;

    .line {
      // width: 1px;
      height: 22px;
      border: 0.5px solid #cccccc;
      margin: 0 12px;
    }

    .groupRouters {
      width: 63%;
      height: 100%;
      display: flex;
      align-items: center;
      border-right: 1px solid #ededed;
      margin-right: 10px;

      span {
        cursor: pointer;
        white-space: nowrap;
        color: #999;

        &:last-child {
          i {
            font-style: normal;
            color: #333;
            font-weight: 700;
          }
        }

        i {
          &:hover {
            color: #52bd68;
          }
        }
      }

      i {
        font-style: normal;
      }
    }

    .discussion-input {
      display: flex;
      margin-right: 10px;

      input {
        // border: none;
        background: #ededed;
        border-radius: 13px;

        &:focus {
          background: #fff;
        }
      }

      .imelink-searchbar__submit {
        color: #666;
      }
    }

    .discussion-close {
      cursor: pointer;
      font-size: 20px;
    }
  }

  .discussion-title {
    width: 100%;
    height: 48px;
    position: relative;
    border-bottom: 1px solid #ededed;
    display: flex;
    align-items: center;
    justify-content: center;
    .currentInfo {
      height: 47px;
      max-width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      .iconStyle {
        font-size: 20px;
        flex-shrink: 0;
        margin-right: 8px;
      }
      .imageStyle {
        width: 24px;
        overflow: hidden;
        flex-shrink: 0;
      }
      .textStyle {
        flex: 1;
        height: 100%;
        margin-left: 8px;
        line-height: 47px;
        text-align: left;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .discussion-add {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      height: 48px;
      display: flex;
      align-items: center;
      padding-left: 24px;
    }
  }

  .class-cont {
    flex: 1;
    min-height: 0;
    height: 487px;
    .cont-wrap {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    // 搜索部分
    .search-warp {
      .folder-wrap {
        .cont-item {
          width: 122px;
          height: auto;
          border: 7px solid #f7f7f7;
          padding: 0;
          padding-top: 20px;
          background: #fff;

          &:hover {
            background: none;
          }

          .item-info {
            margin-top: 10px;
            font-size: 12px;
            background: #f7f7f7;
            width: 100%;
            padding: 8px 0;
          }
        }
      }

      .search-group-item {
        width: 48%;
        margin-right: 10px;
        border-radius: 4px;
        background: #f7f7f7;
        padding: 7px;
        margin-bottom: 14px;
        box-sizing: border-box;

        .group-item-top {
          padding: 5px;
          width: 100%;
          background: #fff;
          display: flex;
          align-items: center;

          img {
            width: 60px;
            height: 60px;
            border-radius: 50%;
          }

          .group-item-r {
            margin-left: 10px;

            .name {
              font-size: 13px;
              color: #333333;
              margin-bottom: 4px;
              overflow: hidden;
              text-overflow: ellipsis; // text-overflow css3的属性，当文本溢出时，显示省略号
              display: -webkit-box;
              -webkit-line-clamp: 1; // 设置两行文字溢出
              -webkit-box-orient: vertical;
              overflow: hidden;
            }

            .time {
              font-size: 12px;
              color: #999999;
            }
          }

          .opera {
            margin-left: auto;
            font-size: 14px;
            color: #999999;
            cursor: pointer;
          }
        }

        .group-item-bottom {
          margin-top: 10px;
          font-size: 12px;
          background: #f7f7f7;
          color: #333333;
          width: 100%;
          padding: 8px 4px;
        }
      }
    }

    .no-data {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top: 0 !important;
    }
  }

  .discussion-operat {
    padding: 20px 0 40px 0;
    display: flex;
    justify-content: center;

    .close {
      width: 90px;
      height: 34px;
      justify-content: center;
      display: flex;
      align-items: center;
      background: #e6e6e6;
      color: #fff;
      border-radius: 4px;
    }

    .check {
      width: 90px;
      height: 34px;
      background: #52bd68;
      justify-content: center;
      display: flex;
      align-items: center;
      color: #fff;
      border-radius: 4px;

      &.is-disabled {
        background: #e6e6e6;
      }
    }
  }
}
</style>
